<template>
    <NavLink
        :type="mapType(content.linkSingle[0].__typename)"
        :url="mapURI(content.linkSingle[0])"
        :blank="content.linkSingle[0].openInNewWindow"
        data-ga-category="announcement-card-click"
        :data-ga-action="mapURI(content.linkSingle[0])"
        :data-ga-label="$route.path"
        data-ga4-event="announcement-card_click"
        class="announcement-card"
        :class="content?.background"
    >
        <ImageAsset
            v-if="content.image[0]"
            class="announcement-card__image"
            :image="content.image[0]"
            :max="980"
            sizes="100vw"
        />
        <div class="announcement-card__content">
            <h3 class="announcement-card__header">{{ content.header }}</h3>

            <!-- eslint-disable vue/no-v-html -->
            <div
                class="announcement-card__description"
                v-html="content.description"
            />
            <!-- eslint-enable vue/no-v-html -->

            <div class="announcement-card__label button--label">
                <span>{{ content.linkSingle[0].header }}</span>
                <BaseIcon icon="arrow-right" />
            </div>
        </div>
    </NavLink>
</template>

<script>
import Navigation from '~/mixins/navigation';
export default {
    mixins: [
        Navigation
    ],

    props: {
        content: {
            type: Object,
            required: true,
            default: () => { return {}; }
        }
    },
};
</script>

<style lang="less" src="./AnnouncementCard.less"></style>
